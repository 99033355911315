// http://ionicframework.com/docs/theming/


// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.

// @import "../www/lib/ionic/scss/ionic.scss";
// $item-kiln-class-filter: " {
// 	'icon ion-close-circled item-kiln-offline': kiln.status == 'Not Connected' || kiln.status == null, 'item-kiln-firing': kiln.status == 'Firing'}
// ";
// @import "roboto";
// @import "noto-sans";
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~@ionic/angular/css/display.css";

.full-page-only {
  display: none !important;
  @media (min-width: 667px) {
    display: none;

    &.show_premium {
		display: flex !important;		
    }
  }
}

.app-only {
  display: flex !important;
  @media (min-width: 667px) {
    display: none !important;
  }
}

.flex {
  display: flex;
  flex: 1 0 auto;
  flex-wrap: wrap;
  width: 100%;
}

ion-header, ion-toolbar {
	button {
		background-color: var(--ion-color-primary, #EE8734);
		color: var(--ion-color-light, white);
	}
}

ion-button, button {
	--color: var(--ion-color-light, white);
	--color-activated: var(--ion-color-light, white);
	--color-focused: var(--ion-color-light, white);
	--background-focused: var(--ion-color-dark, #3d3d3d);
	--background-activated: var(--ion-color-dark, #3d3d3d);
}

.app-header {
	font-size: 20px;
	color: var(--ion-color-light);
	background-color: var(--ion-color-primary);
	text-transform: none;
}

.header-kiln-name {
	color: var(--ion-color-dark);
	text-align: center;
}

.error-box {
	color: var(--ion-color-danger);

	p {
		color: var(--ion-color-danger);
		margin: 0px;
	}
}

.show-updating {
	color: var(--ion-color-primary);
}

.item-alarm-base {
	font-size: 22px;
	padding-top: 4px;
	padding-bottom: 4px;
	color: var(--ion-color-dark);
	text-align: center;
}

.item-alarm-off {
	color: var(--ion-color-dark);
	background-color: var(--ion-color-light);
}

.item-alarm-hold-notice {
	color: var(--ion-color-dark);
	background-color: var(--energized);
}

.item-alarm-error {
  background-color: var(--ion-color-light-shade);
  color: var(--ion-color-danger);
}

ion-footer {
	display: flex;
  flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.toolbar-title {
		font-size: 1em;
		display: flex;
    flex: 1;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		line-height: 1.15em;
	}

	a {
		color: white;
	}
}

ion-modal {
	--min-width: 60%;
	@media (min-height: 600px) {
		--min-height: 600px;
	}
}

app-alert-settings-popup.ion-page, .small-modal .ion-page {
	justify-content: flex-start !important;
	--offset-top: unset !important;
	--offset-bottom: unset !important;
	overflow-y:scroll;

	ion-list {
		background: none !important;
		--background: none !important;
		background-color: none !important;
		overflow-y:scroll;
	}
}


.header-background {
	display: none !important; 
	backdrop-filter: none !important;
}