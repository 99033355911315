:root {
  --ion-color-primary: #B53E15;
  --ion-color-primary-rgb: 234,133,64;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #ce7538;
  --ion-color-primary-tint: #f7cdbe;

  --ion-color-secondary: #586871;
  --ion-color-secondary-rgb: 88,104,113;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #4d5c63;
  --ion-color-secondary-tint: #69777f;

  --ion-color-tertiary: #5d8dda;
  --ion-color-tertiary-rgb: 93,141,218;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #527cc0;
  --ion-color-tertiary-tint: #CEDDF4;

  --ion-color-success: #5cbe85;
  --ion-color-success-rgb: 92,190,133;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #51a775;
  --ion-color-success-tint: #6cc591;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #d5614b;
  --ion-color-danger-rgb: 219,101,77;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0,0,0;
  --ion-color-danger-shade: #c15944;
  --ion-color-danger-tint: #df745f;

  --ion-color-dark: #3d3d3d;
  --ion-color-dark-rgb: 61,61,61;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #363636;
  --ion-color-dark-tint: #505050;

  --ion-color-medium: #8A8D95;
	--ion-color-medium-rgb: 138,141,149;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #797c83;
	--ion-color-medium-tint: #9698a0;

  --ion-color-light-gray: #ECECEC;
  --ion-color-light-gray-shade: #f1f2f6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: var(--ion-color-light);
  --ion-button-color: var(--ion-color-light);

  ion-toolbar.login-as {
    --ion-toolbar-background: var(--ion-color-success);
    --ion-toolbar-color: var(--ion-color-light);
  }

  #main-kiln-link-panel-selected {
    padding: 0;
  }

  button {
    border-radius: 2.5px;
  }
}

input {
  cursor: pointer;

  &:disabled {
    cursor: none;
  }
}

.ios body.premium, .md body.premium, body.premium {
  .logo {
    display: flex;
    flex: 1;
  }

  h4.title {
    background-color: var(--ion-color-medium);
    color: var(--ion-color-light);
    margin: 0;
    padding: 0.75em 0.5em;
    text-align: center;
    width: 100%;
    font-size: 0.95em;
  }

  @media(min-width: 667px) {
    --ion-toolbar-background: var(--ion-color-primary);
    --ion-toolbar-color: var(--ion-color-light);
    --ion-button-color: var(--ion-color-light);

    ion-toolbar.login-as {
      --ion-toolbar-background: var(--ion-color-success);
      --ion-toolbar-color: var(--ion-color-light);
      --ion-button-color: var(--ion-color-light);
    }
  }

  ion-content, ion-list, .manual-list-fullwidth {
    @media(min-width: 667px) {
      --background: var(--ion-color-light-gray);
      background-color: var(--ion-color-light-gray);
      max-height: 100%;
      display: flex;
      flex: 0 1 auto;
      flex-direction: column;
    }

    .group {
      .status {
        @media (min-width: 667px) {
          &.no-show {
            display: none;
          }
        }
      }

      @media(min-width: 667px) {
        background-color: var(--ion-color-light);
      }
    }

    .note-kiln-list {
      .temp {
        @media(min-width: 667px) {
          display: none;
        }
      }

      .status {
        @media (min-width: 667px) {
          display: flex;
          flex: 0.5 0 auto;
        }
      }
    }
  }

  a {
    @media(min-width: 667px){
      color: var(--ion-color-dark) !important;
    }
  }

  .home-page {
    @media(min-width: 667px) {
      &.show_premium {
        #login-list {
          ion-item {
            color: var(--ion-color-dark);
            border: 2px solid var(--ion-color-dark);
          }
        }

        a {
          color: var(--ion-color-dark);
        }

        #forgot-password {
          width: 72.5%;

          > span > a {
            color: var(--ion-color-dark);
          }
        }

        .error-box {
          p {
            color: var(--ion-color-dark) !important;
            border: 1px solid var(--ion-color-dark) !important;
          }
        }

        .login-logo, #login-list, #forgot-password, #login-markdown3 {
          padding-left: 55%;
        }
      }
    }
  }

  #login-list {
    @media(min-width: 667px){
      color: var(--ion-color-dark);
      // width: 40%;
      margin: 0 30%;
    }
  }

  .small-modal {
    .modal-wrapper {
      --max-height: 85%;
      --background: var(--ion-color-light);
      background: var(--ion-color-light);
      --max-width: 90%;
      --width: 85%;

      @media(min-width: 750px) {
        --max-width: 70%;
        --width: 65%;
        margin: 5em 20%;
      }
    }
  }
}

.main-img {
  max-height: 3em;
  @media(max-width: 620px) {
    width: 8em;
    height: auto;
    margin-left: 0.5em;
  }
}